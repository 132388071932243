import React from "react"
import Layout from "../layouts/Layout"
import '../../node_modules/font-awesome/css/font-awesome.min.css';
import '../shared/css/typography.css';
import '../shared/css/ui-elements.css';
import { Expo, TweenMax } from "gsap/TweenMax"
import { graphql, StaticQuery } from "gatsby"
import SEO from "../core/seo"



export const imgContactBig = graphql`
  fragment imgContactBig on File {
    childImageSharp {
      fluid(maxHeight: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

class Contacts extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            bigPic: file(relativePath: { eq: "contacts/img_contacts.png" }) { ...imgContactBig },
          }
        `}
        render={data => <ContactsContent {...this.props}  data={data} /> }
      />
    )
  }
}

class ContactsContent extends React.Component {


  componentDidMount() {
    const bgImg = new Image();
    bgImg.onload = () => this.aniMask();
    // bgImg.src = '../static/home/training-sky.jpg';
    bgImg.src = this.props.data.bigPic.childImageSharp.fluid.src;

    this.showFooterButton();
  }

  showFooterButton() {
    TweenMax.from(".button_footer_left", 0.7, {
      bottom: -60,
      delay: 2,
      ease: Expo.easeInOut
    })
  }

  aniMask() {
    const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    const size = w > h ? w : h;
    TweenMax.to(".clip-circle", 1.00, {
      delay: 0,
      webkitClipPath: `circle(${size/2 + 50} at 50% 50%)`,
      clipPath: `circle(${size/2 + 50} at 50% 50%)`,
      ease: Expo.easeInOut
    });
  }

  render() {
    const isInvoiceShown = this.props.location.href && this.props.location.href.includes('private');
    const styles = {
      //background: `url("../static/home/training-sky.jpg") no-repeat center center`,
      background: `url("${this.props.data.bigPic.childImageSharp.fluid.src}") no-repeat center center`,
      backgroundSize: 'cover'
    }
    return (

      <Layout {...this.props}>
        <SEO title="Contacts"/>

        <div>

          <a href="mailto:hello@fabiobiondi.io">
            <div className="button_footer_left bg-angular">
              CONTACT ME
            </div>
          </a>

          <div className="page_wrapper">

            <div
              className="page_wrapper_side_text"
            >
              <div>
                <div>
                <h1 className="title_text_l">
                  <i className="fa fa-envelope-o"></i> CONTACTS
                </h1>
                  <div className="paragraph">Contact me for support, training courses, code review, events and meetups:</div>
                </div>

                <br/>

                <div>

                  <div className="paragraph">
                    <i className="fa fa-map-marker" style={{ textAlign: 'center', width: '2rem'}}></i>
                    <span className="text-secondary hide-on-mobile"> LOCATION: </span>
                    <strong>NORTH EAST ITALY</strong>
                  </div>

                  <div className="paragraph">
                    <i className="fa fa-envelope-o" style={{ textAlign: 'center', width: '2rem'}}></i>
                    <span className="text-secondary hide-on-mobile"> EMAIL: </span>
                    <a href="mailto:hello@fabiobiondi.io">
                      <strong>hello@fabiobiondi.io</strong>
                    </a>
                  </div>



                  <div className="paragraph">
                    <i className="fa fa-graduation-cap" style={{ textAlign: 'center', width: '2rem'}}></i>
                    <span className="text-secondary hide-on-mobile"> TRAINING: </span>
                    <a href="https://formazione.fabiobiondi.io/" target="_blank"  rel="noopener noreferrer">
                      <strong>formazione.fabiobiondi.io</strong>
                    </a> (italian only)
                  </div>

                  <div className="paragraph">
                    <i className="fa fa-graduation-cap" style={{ textAlign: 'center', width: '2rem'}}></i>
                    <span className="text-secondary hide-on-mobile"> BLOG: </span>
                    <a href="https://www.training.fabiobiondi.io/" target="_blank"  rel="noopener noreferrer">
                      <strong>training.fabiobiondi.io</strong>
                    </a>
                  </div>

                  <div className="separator"></div>

                  <h2 className="title_text_l">
                    FOLLOW ME ON...
                  </h2>

                  <br/>


                  <div className="paragraph">
                    <i className="fa fa-facebook" style={{ textAlign: 'center', width: '2rem'}}></i>
                    <span className="text-secondary hide-on-mobile"> FACEBOOK: </span>
                    <a href="https://www.facebook.com/biondifabio/" target="_blank"  rel="noopener noreferrer">
                      <strong>biondifabio</strong>
                    </a>
                  </div>

                  <div className="paragraph">
                    <i className="fa fa-instagram" style={{ textAlign: 'center', width: '2rem'}}></i>
                    <span className="text-secondary hide-on-mobile"> INSTAGRAM: </span>
                    <a href="https://www.instagram.com/biondifabio/" target="_blank"  rel="noopener noreferrer">
                      <strong>biondifabio</strong>
                    </a>
                  </div>

                  <div className="paragraph">
                    <i className="fa fa-linkedin" style={{ textAlign: 'center', width: '2rem'}}></i>
                    <span className="text-secondary hide-on-mobile"> LINKED IN: </span>
                    <a href="https://www.linkedin.com/in/fabiobiondi/" target="_blank"  rel="noopener noreferrer">
                      <strong>fabiobiondi</strong>
                    </a>
                  </div>

                  <div className="paragraph">
                    <i className="fa fa-youtube" style={{ textAlign: 'center', width: '2rem'}}></i>
                    <span className="text-secondary hide-on-mobile"> YOUTUBE: </span>
                    <a href="https://www.youtube.com/user/BiondiFabio" target="_blank"  rel="noopener noreferrer">
                      <strong>BiondiFabio</strong>
                    </a>
                  </div>

                  <div className="paragraph">
                    <i className="fa fa-twitter" style={{ textAlign: 'center', width: '2rem'}}></i>
                    <span className="text-secondary hide-on-mobile"> TWITTER: </span>
                    <a href="https://twitter.com/biondifabio" target="_blank"  rel="noopener noreferrer">
                      <strong>@biondifabio</strong>
                    </a>
                  </div>

                  <div className="separator" />


                  <h2 className="title_text_l">
                    COMMUNITIES
                  </h2>
                  <div className="paragraph">I'm the founder of following online and offline italian communities:</div>

                  <br/>


                  <div className="paragraph">
                    <a href="https://www.facebook.com/groups/angularjs.developer.italiani" target="_blank"  rel="noopener noreferrer">
                      <span> • ANGULAR <span className="hide-on-mobile">DEVELOPER ITALIANI</span> </span>
                    </a>
                    <strong className="hide-on-mobile">:5000 users</strong>
                  </div>
                  <div className="paragraph">
                    <a href="https://www.facebook.com/groups/angular.fundamentals" target="_blank"  rel="noopener noreferrer">
                      <span> • ANGULAR FUNDAMENTALS</span>
                    </a>
                    <strong className="hide-on-mobile">: ~ 1000 users</strong>
                  </div>

                  <div className="paragraph">
                    <a href="https://www.facebook.com/groups/react.developer.italiani/" target="_blank"  rel="noopener noreferrer">
                      <span> • REACT <span className="hide-on-mobile">DEVELOPER ITALIANI</span></span>
                    </a>
                    <strong className="hide-on-mobile">: +2500 users</strong>
                  </div>

                  <div className="paragraph">
                    <a href="https://www.facebook.com/groups/javascript.developer.italiani" target="_blank"  rel="noopener noreferrer">
                      <span> • JAVASCRIPT <span className="hide-on-mobile">DEVELOPER ITALIANI</span></span>
                    </a>
                    <strong className="hide-on-mobile">: +3000 users</strong>
                  </div>

                  <div className="paragraph">
                    <a href="https://www.facebook.com/groups/opportunita.developer.italiani/" target="_blank"  rel="noopener noreferrer">
                      <span> • OPPORTUNITIES <span className="hide-on-mobile">FOR ITALIAN DEVELOPERS</span></span>
                    </a>
                    <strong className="hide-on-mobile">: 3000 users</strong>
                  </div>

                  <div className="paragraph">
                    <a href="https://www.facebook.com/groups/2409669399267482/" target="_blank"  rel="noopener noreferrer">
                      <span> • FVG DEVS</span>
                    </a>
                    <strong className="hide-on-mobile">: North East Italy community</strong>
                  </div>

                  <br/>
                  <div className="separator" />
                  {
                    isInvoiceShown ? <div>

                      <h2 className="title_text_m">
                        INVOICES
                      </h2>
                      <br/>
                      <div className="paragraph">
                        Fabio Biondi<br/>
                        Via Raparoni 1, 34077 <br/>
                        Ronchi dei Legionari (GO) - Italy <br/>
                        <br/>
                        P.I. <strong>01116230317</strong> <br/>
                        C.F. <strong>BNDFBA77H24D423U</strong> <br/>
                        codice identificativo: <strong>M5UXCR1</strong><br/>
                        PEC: <strong>biondi.fabio@pec.it</strong>
                        <br/>
                        <br/>
                      </div>

                    </div> : null
                  }

                </div>
              </div>
            </div>


            <div
              className="page_wrapper_side_image clip-circle"
              style={styles}
            >


            </div>


          </div>

        </div>
      </Layout>
    )
  }
}

export default Contacts
